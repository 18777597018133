<template lang="pug">
.add-card
    .form-floating.mb-3
        input.form-control(type="text" placeholder=" ")
        label Full Name
    .form-floating.mb-3
        input.form-control(type="text" placeholder=" ")
        label Zip
    .form-floating.mb-3
        input.form-control(type="text" placeholder=" ")
        label Card Number
    .row
        .form-floating.mb-3.col-sm-4
            input.form-control(type="text" placeholder=" ")
            label Month
        .form-floating.mb-3.col-sm-4
            input.form-control(type="text" placeholder=" ")
            label Year
        .form-floating.mb-3.col-sm-4
            input.form-control(type="text" placeholder=" ")
            label CVR
    //.row.text-start
        .container
            .form-check.mb-3
                .form-check-input(type="radio" default)
                .form-check-label $5.00/Month
    button.btn.btn-secondary.mb-3 Validate
</template>

<script>
import API from "./../API.js"
//var stripe = window.Stripe('pk_test_51I23XtAiwTZaeKVnknCUEHCPqUACQgd3mnHLuX0ffj9j5bEGcxsUHu33b3kQ0FTvt022Vh8iOCR9ZEKs7E5QZ1em00rsoT79kG');
//var elements = stripe.elements({
//  clientSecret: 'pk_test_51I23XtAiwTZaeKVnknCUEHCPqUACQgd3mnHLuX0ffj9j5bEGcxsUHu33b3kQ0FTvt022Vh8iOCR9ZEKs7E5QZ1em00rsoT79kG',
//});
//var paymentElement = elements.create('payment');
export default {
    data(){
        return {
            name: "",
            zipCode: "",
            cardNumber: "",
            month: "",
            year: "",
            cvr: "",
            //paymentElement
        }
    },
    methods:{
        submit(){
            const cardInfo = {
                name: this.name,
                address_zip: this.zipCode,
                //"address_city": cardInfo.address_city,
                //"address_country": cardInfo.address_country,
                //"address_line1": cardInfo.address_line1,
                //"address_state": cardInfo.address_state,
                "exp_month": this.month,
                "exp_year": this.year,
            }
            API.addPaymentCard(cardInfo).then(()=>{

            })
        }
    }
}
</script>

<style>

</style>