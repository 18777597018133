<template lang="pug">
.my-profile
    .loading(v-if="loading")
      p Loading...
    .profile-info.card(v-if="profile._id" style="margin-bottom:10px")
      .card-body
        h3 {{profile.profile.firstName}} {{profile.profile.lastName}}
        img.rounded-circle.profile-img.small(v-if="profile.profile.photo" :src="'https://social.emmint.com/' + profile.profile.photo")
        p.small.text-start.description {{profile.profile.description}}
        .row.details.text-start(v-if="profile.isCourse")
          .col-auto(v-if="profile.data.profileImg")
            img.rounded-circle(:src="profile.data.profileImg", alt="profilePhoto"  style="width:100px")
          .col
            span.author(v-if="profile.data.author") By: 
              span.fw-bold {{profile.data.author}}
              br
            span.year(v-if="profile.data.year") 
              span.material-icons date_range
              |  {{profile.data.year}}
              br
            span.duration.mutted.fs-6.fw-light(v-if="profile.data.duration") 
              span.material-icons timer
              |  {{profile.data.duration}}
              br
            span.lang
              span.material-icons language
              |  {{profile.profile.language}}
              br
    .form.card(v-if="profile._id")
      .nav.nav-tabs
        .nav-link(:class="{active: currentTab == 'Profile'}" @click="currentTab = 'Profile'") Profile
        .nav-link(v-if="!profile.isGroup" :class="{active: currentTab == 'Subscription'}" @click="currentTab = 'Subscription'") Subscription
        .nav-link(v-if="!profile.isGroup" :class="{active: currentTab == 'Payments'}" @click="currentTab = 'Payments'") Payments
        .nav-link(v-if="!profile.isGroup" :class="{active: currentTab == 'Credentials'}" @click="currentTab = 'Credentials'") Credentials
        .nav-link.disabled(v-if="!profile.isGroup" :class="{active: currentTab == 'Notifications'}" @click="currentTab = 'Profile'") Notifications
      .card-body
        .profile(v-if="currentTab == 'Profile'")
          .form-floating.mb-3
            input.form-control(type="text" v-model="profile.profile.firstName" placeholder=" ")
            label First Name
          .form-floating.mb-3
            input.form-control(type="text" v-model="profile.profile.lastName" placeholder=" ")
            label Last Name
          .form-floating.mb-3
            textarea.form-control(v-model="profile.profile.description" placeholder=" ")
            label Description
          .form-floating.mb-3
            input.form-control(type="text" v-model="profile.profile.location" placeholder=" " disabled)
            label Location
          UploadImage(ref="uploadProfile" @localLoaded="localLoaded" @remoteLoaded="remoteLoaded" single="true")
          img.rounded-circle.profile-img.pointer(:src="profile.profile.photo || profileImageLocal" @click="$refs.uploadProfile.openFileSelector()" onerror="this.src='https://social.emmint.com/uploads/e6f9be6d665dc43417701bf16a90122c.png';this.onerror='';")
          p Click to edit
          //For Courses
          .course-form(v-if="profile.isCourse")
            h5 Course information:
            .form-floating.mb-3
              input.form-control(type="text" v-model="profile.data.author" placeholder=" ")
              label Author
            .form-floating.mb-3
              input.form-control(type="text" v-model="profile.data.location" placeholder=" ")
              label Location
            .form-floating.mb-3
              input.form-control(type="text" v-model="profile.data.duration" placeholder=" ")
              label Duration
            .form-floating.mb-3
              input.form-control(type="number" v-model="profile.data.videos" placeholder=" ")
              label # of Videos
            .form-floating.mb-3
              input.form-control(type="number" v-model="profile.data.year" placeholder=" ")
              label Year
            .form-floating.mb-3
              input.form-control(type="text" v-model="profile.data.profileImg" placeholder=" ")
              label Course Profile Img URL
          button.btn.btn-primary.m-3(@click="updateProfile") Update
          button.btn.btn-danger(v-if="profile.isGroup" @click="deleteProfile") Delete {{profile.isCourse ? "Course" : "Group"}}
        .payments(v-if="currentTab == 'Payments'")
          .cards.text-start
            //p Registered Cards
            .form-check.mb-3
              .form-check-input(type="radio" checked)
              .form-check-label Subscription 1 Month $10.00 USD
          //h5 Add a card
          //AddCard
          StripeForm(:userid="profile.userid" description="Subscription 1 Month" price='1000')
        .subscription(v-if="currentTab == 'Subscription'")
          p This montly subscription helps us pay for the expenses to make this service available for the family, and gives you unlimited access to all the multimedia material.  
          .row(v-if="profile.subscription && profile.subscription > (new Date() - 0)")
            p Thank you for your contribution.
            p You are subscribed until {{fromNow(profile.subscription)}}
          .row(v-else)
            p Consider subscribing
              button.btn(@click="currentTab = 'Payments'") Here
        .notifications(v-if="currentTab == 'Credentials'")
          .form-floating.mb-3
            input.form-control(type="password" v-model="newPassword" placeholder=" ")
            label Password
          .left
            button.btn.btn-primary(@click="changePassword") Change Password!
        .notifications(v-if="currentTab == 'Notifications'")
          .row.mb-3
            .col-sm-4
              h5 Type
            .col-sm-4
              h5 Email
            .col-sm-4
              h5 Push
          .row.mb-3
            .col-sm-4
              span New posts in your profile
            .col-sm-4
              .form-check
                .form-check-input
            .col-sm-4
              .form-check
                .form-check-input
          .row.mb-3
            .col-sm-4
              span Comments in your posts
            .col-sm-4
              .form-check
                .form-check-input
            .col-sm-4
              .form-check
                .form-check-input
          .row.mb-3
            .col-sm-4
              span New posts in groups you follow
            .col-sm-4
              .form-check
                .form-check-input
            .col-sm-4
              .form-check
                .form-check-input
          .row.mb-3
            .col-sm-4
              span Comments in bookmarked posts
            .col-sm-4
              .form-check
                .form-check-input
            .col-sm-4
              .form-check
                .form-check-input
</template>

<script>
import UserName from "./../components/UserName.vue";
import UploadImage from "./../components/UploadImage.vue";
import AddCard from "./../components/AddCard.vue";
import StripeForm from "./../components/StripeForm.vue";
import API from "./../API.js";
export default {
    props: ["user"],
    components: {UserName, UploadImage, AddCard, StripeForm},
    data(){
        return {
            profile: {},
            profileImageLocal: "",
            loading: true,
            currentTab: "Profile",
            newPassword: ''
        }
    },
    mounted(){
      this.profile = JSON.parse(JSON.stringify(this.user));
      this.loading = false;
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      const subscribe = new URLSearchParams(window.location.search).get(
        "subscribe"
      );
      if(clientSecret || subscribe){
        this.currentTab = "Payments";
      }

    },
    methods:{
      localLoaded(images){
        this.profileImageLocal = images[0];
      },
      remoteLoaded(image){
        this.profile.profile.photo = image;
      },
      updateProfile(){
        API.updateMyProfile(this.profile.profile, this.profile.data).then((r)=>{
          alert(r.status)
        })
      },
      deleteProfile(){
        API.deleteProfile(this.profile._id);
      },
      fromNow(ts){
        return window.moment(ts).format("MMM Do YY");
      },
      changePassword(){
        let password = this.newPassword;
        console.log("change password called")
        if(password.length < 6) {
          alert('Password must be at least 6 characters');
          return;
        }
        API.changePassword(password).then(()=>{
          alert('Password changed successfully')
        });
      }
    }
}
</script>

<style lang="sass">

</style>