<template>
  <h5 v-if="price" class="text-start">Amount: ${{price / 100}}</h5>
  <form id="payment-form" v-if="!paymentStatus">
    <div id="payment-element">
      <!--Stripe.js injects the Payment Element-->
    </div>
    <button id="submit" class="btn btn-secondary m-3">
      <div class="spinner hidden" id="spinner"></div>
      <span id="button-text" class="">Donate</span>
    </button>
    <div id="payment-message" class="hidden"></div>
  </form>
  <h3 v-if="paymentStatus">{{paymentStatus}}</h3>
</template>

<script>
// const baseUrl = window.location.href.includes("localhost")
//   ? "http://localhost:3000"
//   : "https://api.emmint.com";
const public_key = window.location.href.includes("localhost")
  ? "pk_test_51I23XtAiwTZaeKVnknCUEHCPqUACQgd3mnHLuX0ffj9j5bEGcxsUHu33b3kQ0FTvt022Vh8iOCR9ZEKs7E5QZ1em00rsoT79kG"
  : "pk_live_51I23XtAiwTZaeKVnsZcIcKDcpr5kyDqTap39Qb6LdGUiEX6E8bFac6nSGHNaVBzcfQnKadrud2t8nPvszhNwbibV00zSvzkJvI";
let stripe = window.Stripe(public_key);
import API from "../API.js";
export default {
  props: ["userid", "price", "description"],
  data() {
    return {
      receipt_email: "",
      paymentStatus: "",
      amount: "",
    };
  },
  mounted() {
    let elements;

    setTimeout(()=>{
      document
      .querySelector("#payment-form")
      .addEventListener("submit", handleSubmit);
    }, 1000)
    

    // Fetches a payment intent and captures the client secret
    let initialize = async () => {
      // const response = await fetch(baseUrl + "/payments/create-payment-intent", {
      //   method: "POST",
      //   mode: 'cors',
      //   credentials: 'include',
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ userid: this.userid, price: this.price }),
      // });
      // let responseObj = await response.json();
      // console.log(responseObj)
      const responseObj = await API.paymentIntent(this.userid, this.price, this.description);
      const { clientSecret, email } = responseObj;
      this.receipt_email = email;

      const appearance = {
        theme: "flat",
      };
      elements = stripe.elements({ appearance, clientSecret });

      const paymentElement = elements.create("payment");
      paymentElement.mount("#payment-element");
    }

    let handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: window.location.href,
          receipt_email: this.receipt_email,
        },
      });
      console.log(error)

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        showMessage(error.message);
      } else {
        showMessage("An unexpected error occured.");
      }

      setLoading(false);
    };

    // Fetches the payment intent status after payment submission
    let checkStatus= async ()=> {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );
      API.paymentRegister(this.userid, paymentIntent);

//    console.log(paymentIntent)
//{
//     "id": "pi_3KEqAlAiwTZaeKVn18SBgacC",
//     "object": "payment_intent",
//     "amount": 10000,
//     "automatic_payment_methods": null,
//     "canceled_at": null,
//     "cancellation_reason": null,
//     "capture_method": "automatic",
//     "client_secret": "pi_3KEqAlAiwTZaeKVn18SBgacC_secret_Ki4vwp4mpVAQ4IolAIBg0HFiB",
//     "confirmation_method": "automatic",
//     "created": 1641453247,
//     "currency": "usd",
//     "description": null,
//     "last_payment_error": null,
//     "livemode": false,
//     "next_action": null,
//     "payment_method": "pm_1KEqB1AiwTZaeKVngEUo62IV",
//     "payment_method_types": [
//         "card"
//     ],
//     "processing": null,
//     "receipt_email": "aeroreyna@gmail.com",
//     "setup_future_usage": null,
//     "shipping": null,
//     "source": null,
//     "status": "succeeded"
// }

      this.paymentStatus = paymentIntent.status;
      this.amount = paymentIntent.amount / 100;

      switch (paymentIntent.status) {
        case "succeeded":
          showMessage("Payment succeeded!");
          this.paymentStatus = "Payment succeeded!";
          break;
        case "processing":
          showMessage("Your payment is processing.");
          this.paymentStatus = "Your payment is processing.";
          break;
        case "requires_payment_method":
          showMessage("Your payment was not successful, please try again.");
          this.paymentStatus = "Your payment was not successful, please try again.";
          break;
        default:
          showMessage("Something went wrong.");
          break;
      }
    }

    initialize();
    checkStatus();

    // ------- UI helpers -------

    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
    }

    // Show a spinner on payment submission
    function setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    }
  },
};
</script>

<style>
</style>