<template lang="pug">
.upload-image
    input#uploadImages(type="file" style="display:none" accept="image/*" :multiple="single ? false : true ")
</template>

<script>
export default {
    data(){
        return {
            localImages: [],
            fileRemote: [],
            progress: 0,
        }
    },
    props:["single"],
    methods:{
        async uploadFile(files) {
            for(let i=0; i<files.length; i++){
                const fd = new FormData();
                fd.append("banner", files[i]);
                return fetch("https://social.emmint.com/upload.php", {
                        method: "POST",
                        body: fd,
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        this.$emit('remoteLoaded', data.fileName, i);
                        return data.fileName;
                    })
                    .catch((err) => console.error(err));
            }
        },
        openFileSelector() {
            document.getElementById("uploadImages").click();
        },
    },
    mounted(){
        let input = document.getElementById("uploadImages");
        input.addEventListener("change", async () => {
            let files = [];
            this.uploadFile(input.files);
            for(let i=0; i<input.files.length; i++){
            //input.files.forEach((file)=>{
                files.push(new Promise((resolve)=>{
                    var reader = new FileReader();
                    reader.onload = function(){
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(input.files[i]);
                }))
            }
            return Promise.all(files).then((images) => {
                this.localImages = images;
                this.$emit('localLoaded', images);
                this.uploadFile(files);
            });
        });        
    }
}
</script>

<style>

</style>